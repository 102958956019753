import React from 'react';
import { useContext } from 'react';
import {FormFeed} from './_form';
import {FeedsContext} from '../../context'; 
import { useNavigate, useParams } from 'react-router-dom';
import { updateFeed } from '../../data';


export const EditFeed = (props) => {
  // eslint-disable-next-line
  const { feeds, setFeeds } = useContext(FeedsContext);
  const navigate = useNavigate();  
  const params = useParams();
  
  const feedToEdit = feeds.find( (x) => x.id === params.feedId)
  
  const handleOnSubmit = (feed ) => {
    updateFeed(feed)
    navigate('/listfeeds');
  };  
  
  
  return (
     <React.Fragment>
      <FormFeed feed={feedToEdit} onSubmit={handleOnSubmit} />
     </React.Fragment>
  );
}