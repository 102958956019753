// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Nutrient, NutrientMeasurement, Feed } = initSchema(schema);

export {
  Nutrient,
  NutrientMeasurement,
  Feed
};