import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AmplifyProvider } from "@aws-amplify/ui-react";
import App from '../App';

import { FeedsContext, NutrientsContext, NutrientMeasurementsContext } from '../context'

import { ListFeed, NewFeed, EditFeed } from '../components/Feed';
import { ListNutrient, NewNutrient, EditNutrient } from '../components/Nutrient';

import { 
  fetchFeeds, createFeedSubscriptions,
  fetchNutrients, createNutrientSubscriptions,
  fetchNutrientMeasurements, createNutrientMeasurementSubscriptions 
} from '../data';

import {
  withAuthenticator,
} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';


const AppRouter = ({ signOut }) => {
  const [feeds, setFeeds] = useState( [] );
  const [nutrients, setNutrients] = useState( [] );
  const [nutrientMeasurements, setNutrientMeasurements] = useState ( [] );
  
  const INSERT_FEED = (feed) => { 
    const found = feeds.find(f => f.id === feed.id);
    if(found === undefined) {
      setFeeds([...feeds, feed]); 
    } 
  };
  const UPDATE_FEED = (feed) => {
    const found = feeds.find(f => f.id === feed.id);
    if(found != undefined) {
      const objIndex = feeds.findIndex(obj => obj.id === feed.id);
      if(found._version < feed._version) {
        setFeeds( [
          ...feeds.slice(0, objIndex),
          feed,
          ...feeds.slice(objIndex + 1),
        ]);
      };
    };
  }; 
  const DELETE_FEED = (feed) => {
    const found = feeds.find(f => f.id === feed.id);
    if(found != undefined) {
      setFeeds(feeds.filter( (f) => f.id !== feed.id ));
    } 
  };
// eslint-disable-next-line
  const feedSubscription = createFeedSubscriptions(INSERT_FEED, UPDATE_FEED, DELETE_FEED);

  const INSERT_NUTRIENT = (nutrient) => { 
    const found = nutrients.find(f => f.id === nutrient.id);
    if(found === undefined) {
      setNutrients([...nutrients, nutrient]); 
    } 
  }
  const UPDATE_NUTRIENT = (nutrient) => {
    const found = nutrients.find(f => f.id === nutrient.id);
    if(found != undefined) { 
      const objIndex = nutrients.findIndex(obj => obj.id === nutrient.id);
      if(found._version < nutrient._version) {
        setNutrients( [
          ...nutrients.slice(0, objIndex),
          nutrient,
          ...nutrients.slice(objIndex + 1),
        ]);
      };
    };
  };    
  const DELETE_NUTRIENT = (nutrient) => {
    const found = nutrients.find(f => f.id === nutrient.id);
    if(found != undefined) {
      setNutrients(nutrients.filter( (f) => f.id !== nutrient.id ));
    }
  };
// eslint-disable-next-line
  const nutrientSubscription = createNutrientSubscriptions(INSERT_NUTRIENT, UPDATE_NUTRIENT, DELETE_NUTRIENT);

  const INSERT_NUTRIENTMEASUREMENT = (nutrientMeasurement) => { 
    const found = nutrientMeasurements.find(f => f.id === nutrientMeasurement.id);
    if(found === undefined) {
      setNutrientMeasurements([...nutrientMeasurements, nutrientMeasurement]); 
    }; 
  };    
  const UPDATE_NUTRIENTMEASUREMENT = (nutrientMeasurement) => {
    const found = nutrientMeasurements.find(f => f.id === nutrientMeasurement.id);
    if(found != undefined) {    
      const objIndex = nutrientMeasurements.findIndex(obj => obj.id === nutrientMeasurement.id);
      if(found._version < nutrientMeasurement._version) {
        setNutrientMeasurements( [
          ...nutrientMeasurements.slice(0, objIndex),
          nutrientMeasurement,
          ...nutrientMeasurements.slice(objIndex + 1),
        ]);
      };
    };
  }; 
  const DELETE_NUTRIENTMEASUREMENT = (nutrientMeasurement) => {
    const found = nutrientMeasurements.find(f => f.id === nutrientMeasurement.id);
    if(found === undefined) {    
      setNutrientMeasurements(nutrientMeasurements.filter( (f) => f.id !== nutrientMeasurement.id ));
    };
  };
// eslint-disable-next-line
  const nutrientMeasurementSubscription = createNutrientMeasurementSubscriptions(INSERT_NUTRIENTMEASUREMENT, UPDATE_NUTRIENTMEASUREMENT, DELETE_NUTRIENTMEASUREMENT);
  
  
  useEffect(() => {
    fetchFeeds().then(
      function(value) {setFeeds( value ); },
      function(error) {console.log("Error in calling fetchFeeds error=",error)}
    );
    fetchNutrients().then(
      function(value) {setNutrients( value );},
      function(error) {console.log("Error in calling setNutrients error=",error)}
    );
    fetchNutrientMeasurements().then(
      function(value) {setNutrientMeasurements( value );},
      function(error) {console.log("Error in calling setNutrientMeasurements error=",error)}
    );
    
    return () => nutrientSubscription.unsubscribe();
  }, []);
  
  return (
    <BrowserRouter>
      <FeedsContext.Provider value={{ feeds, setFeeds }}>
      <NutrientsContext.Provider value={{ nutrients, setNutrients }}>
      <NutrientMeasurementsContext.Provider value={{ nutrientMeasurements, setNutrientMeasurements }}>
      <AmplifyProvider>
        <Routes>
          <Route path="/" element={<App />} >
            <Route path="listfeeds" element={<ListFeed />} />
            <Route path="addfeed" element={<NewFeed />} />
            <Route path="feed"  >
              <Route path=":feedId" element={<EditFeed />} />
            </Route>  
            <Route path="listnutrients" element={<ListNutrient />} />
            <Route path="addnutrient" element={<NewNutrient />} />
            <Route path="nutrient" >
              <Route path=":nutrientId" element={<EditNutrient />} />
            </Route>  
            <Route path="*" element={<p>There is nothing here!</p> } />
          </Route>
        </Routes>
      </AmplifyProvider>
      </NutrientMeasurementsContext.Provider>
      </NutrientsContext.Provider>
      </FeedsContext.Provider>
    </BrowserRouter>
  );
};


export default withAuthenticator(AppRouter);



// function getFeeds() {
//   return [
//     {
//       id: "1",
//       name: "Purina Omega Match Ration Balancing Horse Feed",
//       unitOfMeasure: "lb",
//       measure: 40.0,
//       Nutrients: [
//         {
//           id: "1",
//           name: "Crude Protein",
//           amount: 13.5,
//           boundry: "min",
//           feedID: "1"
//         },
//         {
//           id: "2",
//           name: "Lysine",
//           amount: 1.8,
//           boundry: "min",
//           feedID: "1"
//         },
//         {
//           id: "3",
//           name: "Crude Fiber",
//           amount: 20.0,
//           boundry: "max",
//           feedID: "1"
//         }
//       ]
//     },
//     {
//       id: "2",
//       name: "Triple Crown 30% Ration Balancer",
//       unitOfMeasure: "lb",
//       measure: 50.0,
//       Nutrients: [
//         {
//           id: "4",
//           name: "Crude Protein",
//           amount: 10.5,
//           boundry: "min",
//           feedID: "2"
//         },
//         {
//           id: "5",
//           name: "Lysine",
//           amount: 5.8,
//           boundry: "min",
//           feedID: "2"
//         },
//         {
//           id: "6",
//           name: "Crude Fiber",
//           amount: 10.0,
//           boundry: "max",
//           feedID: "2"
//         }
//       ]
//     },
//     {
//       id: "3",
//       name: "Nutrena SafeChoice Original Horse Feed",
//       unitOfMeasure: "lb",
//       measure: 40.0,
//       Nutrients: [
//         {
//           id: "7",
//           name: "Crude Protein",
//           amount: 14.0,
//           boundry: "min",
//           feedID: "3"
//         },
//         {
//           id: "8",
//           name: "Lysine",
//           amount: 0.9,
//           boundry: "min",
//           feedID: "3"
//         },
//         {
//           id: "9",
//           name: "Crude Fiber",
//           amount: 15.0,
//           boundry: "max",
//           feedID: "3"
//         }
//       ]
//     }
//   ];
// }
