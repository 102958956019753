import React from 'react';
import {
  Card,
  Button,
  Heading,
  View,
} from "@aws-amplify/ui-react";
import { useNavigate } from 'react-router-dom';

import { MdEditNote, MdDeleteForever } from 'react-icons/md';

const ShowFeed = (props) => {
  const history = useNavigate();
  const { feed, handleRemove } = props
  // const { id, name, unitOfMeasure, measure, picture, handleRemoveFeed }  = props
  
  return (
    <Card variation="elevated">
      <Heading level={5}>{feed.name}</Heading>
        <View>
          <div> {feed.measure} {feed.unitOfMeasure} </div>
          <div> Nutrients: {feed.nutrientMeasurements?.length} </div>
        </View>
        <Button size="small" variation="primary" onClick={() => history(`/feed/${feed.id}`)}>
          <MdEditNote/> &nbsp; Edit
        </Button>
        <Button size="small" onClick={() => handleRemove( feed.id )}>
          <MdDeleteForever/> &nbsp; Delete
        </Button>
    </Card>
  );
  
}

export default ShowFeed