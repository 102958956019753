import React from 'react';
import {FormFeed} from './_form';
import { useNavigate } from 'react-router-dom';
import { createFeed, createNutrientMeasurement } from '../../data'


export const NewFeed = () => {
  const navigate = useNavigate();  
  
  const handleOnSubmit = ( feed ) => {
    delete feed.id;
    createFeed(feed).then(
      function(value) { 
        feed?.nutrientMeasurements.every( (item, index) => {
          let nutrientMeasurement = {...item, 'feedID': value.id};
          delete nutrientMeasurement.id;
          createNutrientMeasurement(nutrientMeasurement);
          return true;
        });
      },
      function(error) { 
        console.log("Error in creating Feed error=", error, feed);
      }
    );
    navigate('/listfeeds');
  };  
  
  
  return (
     <React.Fragment>
      <FormFeed onSubmit={handleOnSubmit} />
     </React.Fragment>
  );
}