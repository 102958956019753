import React from 'react';
import { useContext } from 'react';

import ShowNutrient from './Show';
import {NutrientsContext} from '../../context'; 
import { deleteNutrient } from '../../data'
import {
  Collection,
  Badge
} from "@aws-amplify/ui-react";

export const ListNutrient = () => {
  // eslint-disable-next-line
  const { nutrients, setNutrients } = useContext(NutrientsContext);

  const handleRemoveNutrient = (id ) => {
    deleteNutrient(id )
  };
  
  return (
    <React.Fragment>
      <Badge variation="info" size="large">Count {nutrients?.length}</Badge>
      <Collection type="list" items={nutrients} direction="row"  wrap="wrap">
        {(nutrient, index) => ( 
          <ShowNutrient key={nutrient.id} nutrient={nutrient} handleRemove={handleRemoveNutrient} />
        )}
      </Collection>
    </React.Fragment>
  );
};



