import React from 'react';
import { useContext } from 'react';

import {FormNutrient} from './_form';
import {NutrientsContext} from '../../context'; 
import { useNavigate, useParams } from 'react-router-dom';
import { updateNutrient } from '../../data'


export const EditNutrient = (props) => {
  // eslint-disable-next-line
  const { nutrients, setNutrients } = useContext(NutrientsContext);
  const navigate = useNavigate();  
  const params = useParams();
  
  const nutrientToEdit = nutrients.find( (x) => x.id === params.nutrientId)
  
  const handleOnSubmit = (nutrient ) => {
    updateNutrient(nutrient)
    navigate('/listnutrients');
  };  
  
  
  return (
     <React.Fragment>
      <FormNutrient nutrient={nutrientToEdit} onSubmit={handleOnSubmit} />
     </React.Fragment>
  );
}