import { DataStore } from '@aws-amplify/datastore';
import { Feed } from '../models';

export async function fetchFeeds() {
  const models = await DataStore.query(Feed);
  return models;
}

export function createFeedSubscriptions(insertFun, updateFun, deleteFun) {
  const subscription = DataStore.observe(Feed).subscribe(msg => {
    switch(msg.opType){
    case "DELETE":
      deleteFun(msg.element);
      break;
    case "INSERT":
      insertFun(msg.element);
      break;
    case "UPDATE":
      updateFun(msg.element);
      break;
    default:
      break;
    }
  });
  return subscription;
}

export async function createFeed(feed) {
  if (!feed.name || !feed.unitOfMeasure || !feed.measure) return;
  return await DataStore.save(
    new Feed( feed )
    // new Feed({
    //   name: feed.name,
    //   measure: feed.measure,
    //   unitOfMeasure: feed.unitOfMeasure
    // })
  );
}

export async function updateFeed(feed) {
  if (!feed.name || !feed.unitOfMeasure || !feed.measure) return;
  const original = await DataStore.query(Feed, feed.id);
  await DataStore.save(
      Feed.copyOf(original, updated => {
        updated.name = feed.name;
        updated.unitOfMeasure = feed.unitOfMeasure;
        updated.measure = feed.measure;
      })
    );
}

export async function deleteFeed( id ) {
  const modelToDelete = await DataStore.query(Feed, id);
  DataStore.delete(modelToDelete);
}


