import React from 'react';
import {
  Card,
  Button,
  Heading,
  View,
} from "@aws-amplify/ui-react";
import { useNavigate } from 'react-router-dom';

import { MdEditNote, MdDeleteForever } from 'react-icons/md';

const ShowNutrient = (props) => {
  const history = useNavigate();
  const { nutrient, handleRemove } = props
  
  return (
    <Card variation="elevated">
      <Heading level={5}>{nutrient.name}</Heading>
        <View as="div">
          <div> Linked to { nutrient.nutrientMeasurements?.length } Measurements </div>
        </View>
        <Button size="small" variation="primary" onClick={() => history(`/nutrient/${nutrient.id}`)}>
          <MdEditNote/> &nbsp; Edit
        </Button>
        <Button size="small" onClick={() => handleRemove( nutrient.id )}>
          <MdDeleteForever/> &nbsp; Delete
        </Button>
    </Card>
  );
  
}

export default ShowNutrient