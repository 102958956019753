import React from 'react';
import {FormNutrient} from './_form';
import { useNavigate } from 'react-router-dom';
import { createNutrient } from '../../data'


export const NewNutrient = () => {
  const navigate = useNavigate();  
  
  const handleOnSubmit = (nutrient ) => {
    createNutrient(nutrient)
    // setNutrients([...nutrients, nutrient]);
    navigate('/listnutrients');
  };  
  
  
  return (
     <React.Fragment>
      <FormNutrient onSubmit={handleOnSubmit} />
     </React.Fragment>
  );
}