import React from 'react';
import { useContext } from 'react';
import ShowFeed from './Show';
import {FeedsContext} from '../../context'; 
import { deleteFeed } from '../../data'
import {
  Collection,
  Badge
} from "@aws-amplify/ui-react";

export const ListFeed = () => {
  // eslint-disable-next-line
  const { feeds, setFeeds } = useContext(FeedsContext);

  const handleRemoveFeed = (id ) => {
    deleteFeed(id )
  };
  
  return (
    <React.Fragment>
      <Badge variation="info" size="large">Count {feeds.length}</Badge>
      <Collection type="list" items={feeds} direction="row"  wrap="wrap">
        {(feed, index) => ( 
          <ShowFeed key={feed.id} feed={feed} handleRemove={handleRemoveFeed} />
        )}
      </Collection>
    </React.Fragment>
  );
};



