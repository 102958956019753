import React from 'react';
import { useState, useContext } from "react";
import { 
  TextField, 
  Flex, 
  Button, 
  Divider,
  Card,
  Heading,
  Collection,
  SelectField
} from '@aws-amplify/ui-react';
import { NutrientsContext } from '../../context'; 

export const FormFeed = (props) => {
  const { feed, onSubmit} = props;
  
  const nutriatFormState = {
    name: '',
    boundry: 'min',
    measure: 0.0,
    unitOfMeasure: '%',
    index: 0,
    nutrientID: null,
    feedID: null
  };
  
  const initialFormState = {
    id: feed?.id ?? null,
    name: feed?.name ?? '',
    unitOfMeasure: feed?.unitOfMeasure ?? 'lbs',
    measure: feed?.measure ?? 0.0,
    nutrientMeasurements: feed?.nutrientMeasurements ?? []
  };
  const [formData, setFormData] = useState(initialFormState);

  
  async function createFeed() {
     if (!formData.name || !formData.unitOfMeasure || !formData.measure) return;
    onSubmit(formData);
    setFormData(initialFormState);
  }
  
  function createMeasurement() {
    setFormData({ ...formData, 'nutrientMeasurements': [...formData.nutrientMeasurements, nutriatFormState] });
  }
  
  function removeMeasurement(objIndex) {
    setFormData({ 
      ...formData, 
      'nutrientMeasurements': formData.nutrientMeasurements.filter( (f) => f.index !== objIndex) 
    });
  };
  
  function changeData(index, name, value) {
    let nm = formData.nutrientMeasurements;
    nm[index][name] = value;
    setFormData({ ...formData, 'nutrientMeasurements': nm});
  };
  
  function getData(index, name) {
    return formData.nutrientMeasurements[index][name];
  };
  
  return (
    <Card variation="elevated" width="30rem">
      <Flex as="form" direction="column" >
        <Heading level={2}>Feed</Heading>
        <TextField 
          label="Name" 
          type="text" 
          isRequired={true} 
          value={formData.name}
          onChange={(event) => {
            setFormData({ ...formData, 'name': event.target.value});
          }}
        />
          
        <TextField 
          label="Measure" 
          type="number" 
          isRequired={true} 
          value={formData.measure}
          onChange={(event) => {
            setFormData({ ...formData, 'measure': event.target.value * 1.0});
          }}
        />
          
        <TextField 
          label="Unit of Measure" 
          type="text" 
          isRequired={true} 
          value={formData.unitOfMeasure}
          onChange={(event) => {
            setFormData({ ...formData, 'unitOfMeasure': event.target.value});
          }}
        />
          
        <Divider />
        <Flex justifyContent='space-between'>
          <Heading level={3}>Nutrients</Heading> 
          <Button onClick={() => {console.log("formData=>",formData);}} >I</Button>
          <Button variation="primary" onClick={() => {createMeasurement();}} >+</Button>
        </Flex>
        <Collection
          type="list"
          items={formData.nutrientMeasurements} >
          {(item, index) => (
            <FormMeasurement key={index} index={index} onRemove = {removeMeasurement} changeData = {changeData} getData = {getData} />
          )}
        </Collection>

        <Divider />

        <Button type="submit" onClick={() => {createFeed();}} >Submit</Button>
      </Flex>
    </Card>
  );
};

const FormMeasurement = (props) => {
  const {index, onRemove, changeData, getData} = props;
  // eslint-disable-next-line
  const { nutrients, setNutrients } = useContext(NutrientsContext);

  // TODO: need to set SelectField to value if in edit mode
  return (
    <Card variation='outlined'>
        <Flex justifyContent='space-between'>
          <Heading level={3}>Measurement {index}</Heading> 
          <Button variation="primary" onClick={() => {onRemove(index);}} >-</Button>
        </Flex>
    
      <SelectField 
        label="Nutrient"
        placeholder="Please select a nutrient..."
        isRequired={true} 
        value={ getData(index, 'name') }
        onChange={(event) => {
          changeData(index, 'name', event.target.selectedOptions[0].label);
          changeData(index, 'nutrientID', event.target.value);
        }}
        >
        {nutrients?.map( (item, index) => (
          <option label={item.name} value={item.id} key={`${item.name}-${index}`}>{item.name}</option>
        ))}
      </SelectField>
    
      <TextField 
        label="Boundry" 
        type="text" 
        isRequired={true} 
        value={getData(index, 'boundry')}
        onChange={(event) => {
          changeData(index, 'boundry', event.target.value);
        }}
      />
  
      <TextField 
        label="Measure" 
        type="number" 
        isRequired={true} 
        value={getData(index, 'measure')}
        onChange={(event) => {
          changeData(index, 'measure', event.target.value * 1.0);
        }}
      />
  
      <TextField 
        label="Unit of Measure" 
        type="text" 
        isRequired={true} 
        value={getData(index, 'unitOfMeasure')}
        onChange={(event) => {
          changeData(index, 'unitOfMeasure', event.target.value);
        }}
      />
    </Card>
    
  );
};