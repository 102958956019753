import { DataStore } from '@aws-amplify/datastore';
import { NutrientMeasurement } from '../models';

export async function fetchNutrientMeasurements() {
  const models = await DataStore.query(NutrientMeasurement);
  return models;
}

export function createNutrientMeasurementSubscriptions(insertFun, updateFun, deleteFun) {
  const subscription = DataStore.observe(NutrientMeasurement).subscribe(msg => {
    switch(msg.opType){
    case "DELETE":
      deleteFun(msg.element);
      break;
    case "INSERT":
      insertFun(msg.element);
      break;
    case "UPDATE":
      updateFun(msg.element);
      break;
    default:
      break;
    }
  });
  return subscription;
}

export async function createNutrientMeasurement(nutrientMeasurement) {
  if (!nutrientMeasurement.name ) return;
  return await DataStore.save(
    new NutrientMeasurement(nutrientMeasurement)
    // new NutrientMeasurement({
    //   name: nutrientMeasurement.name,
    //   amount: nutrientMeasurement.amount,
    //   boundry: nutrientMeasurement.boundry,
    // })
  );
}

export async function updateNutrientMeasurement(nutrientMeasurement) {
  if (!nutrientMeasurement.name ) return;
  const original = await DataStore.query(NutrientMeasurement, nutrientMeasurement.id);
  await DataStore.save(
      NutrientMeasurement.copyOf(original, updated => {
        updated.name = nutrientMeasurement.name;
        updated.amount = nutrientMeasurement.amount;
        updated.boundry = nutrientMeasurement.boundry;
      })
    );
}

export async function deleteNutrientMeasurement( id ) {
  const modelToDelete = await DataStore.query(NutrientMeasurement, id);
  DataStore.delete(modelToDelete);
}


