import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Nutrient } from '../models';

export async function fetchNutrients() {
  const models = await DataStore.query(Nutrient, Predicates.ALL, {
    sort: s => s.name(SortDirection.ASCENDING)
  });
  return models;
}

export function createNutrientSubscriptions(insertFun, updateFun, deleteFun) {
  const subscription = DataStore.observe(Nutrient).subscribe(msg => {
    switch(msg.opType){
    case "DELETE":
      deleteFun(msg.element);
      break;
    case "INSERT":
      insertFun(msg.element);
      break;
    case "UPDATE":
      updateFun(msg.element);
      break;
    default:
      break;
    }
  });
  return subscription;
}

export async function createNutrient(nutrient) {
  if (!nutrient.name ) return;
  return await DataStore.save(
    new Nutrient({
      name: nutrient.name
    })
  );
}

export async function updateNutrient(nutrient) {
  if (!nutrient.name ) return;
  const original = await DataStore.query(Nutrient, nutrient.id);
  // await DataStore.save( nutrient );
  await DataStore.save(
      Nutrient.copyOf(original, updated => {
        updated.name = nutrient.name
      })
    );
}

export async function deleteNutrient( id ) {
  const modelToDelete = await DataStore.query(Nutrient, id);
  DataStore.delete(modelToDelete);
}


