import React from 'react';
import { useState } from 'react';
import { 
  TextField, 
  Flex, 
  Button, 
  Divider,
  Card
} from '@aws-amplify/ui-react';

export const FormNutrient = (props) => {
  const { nutrient, onSubmit} = props;
  
  const initialFormState = {
    id: nutrient?.id ?? null,
    name: nutrient?.name ?? ''
  }
  const [formData, setFormData] = useState(initialFormState);

  
  async function createNutrient() {
    if (!formData.name ) return;
    onSubmit(formData);
    setFormData(initialFormState);
  }
  
  return (
    <Card variation="elevated" width="20rem">
      <Flex as="form" direction="column" >
        <TextField 
          label="Name" 
          type="text" 
          isRequired={true} 
          value={formData.name}
          onChange={(event) => {
            setFormData({ ...formData, 'name': event.target.value});
          }}
        />
        <Divider />
        <Button type="submit" onClick={() => {createNutrient();}} >
          Submit</Button>
      </Flex>
    </Card>
  );
};

