import React from 'react';
import { useState } from 'react';
import { 
  Menu, 
  MenuItem, 
  View,
  Card,
  Flex,
  SearchField,
  Icon,
  Image,
  Link
} from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';

import {
  Link as ReactRouterLink,
} from 'react-router-dom';

export const PrimaryNavBar = ( props ) => {
  const { menus } = props;
  // console.log("menus=",menus);
  
  return (
    <Card
      variation="elevated" alignItems="center">
      <Flex alignItems="center" justifyContent="space-around">
        <Flex 
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          padding="0px 0px 0px 0px"
        >
          <HamburgerBar links = {menus} />
          <LinkBar links = {menus} />
        </Flex>
        <SearchBar />
      </Flex>
    </Card>
  );
};


const LinkBar = ( props ) => {
  const { links } = props;
  
  return (
      <Flex alignContent="flex-end"  >
        {links?.map( ([key, value]) => (
          <Link key={value} as={ReactRouterLink} to={value}>{ key }</Link>
        ))}
      </Flex>
  );
};

const HamburgerBar = ( props ) => {
  const { links } = props;
  const [isOpen, setIsOpen] = useState(false);
  
  const handleOpenChange = (open) => {
    setIsOpen(open);
    // Do something else
  };
  const closeMenu = () => setIsOpen(false);

  return (
      <View width="4rem">
        <Menu
          menuAlign="start"
          size="large"
          isOpen={isOpen}
          onOpenChange={handleOpenChange}
        >
          {links?.map( ([key, value]) => (
            <MenuItem onClick={() => { closeMenu(); }} key={value} >
              <Link key={value} as={ReactRouterLink} to={value}>{ key }</Link>
            </MenuItem>
          ))}
        </Menu>
      </View>
    
  );
};


const SearchBar = ( props ) => {
  const { overrides } = props;
  
  // eslint-disable-next-line
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  
  function getOverrideProps( overrides, value ) {
    // return overrides[value];
    return {};
  }
  
  return (
  <Flex
    gap="32px"
    direction="row"
    width="40%"
    justifyContent="flex-end"
    alignItems="center"
    padding="0px 0px 0px 0px"
  >
    <SearchField
      display="flex"
      gap="8px"
      direction="column"
      width="300px"
      shrink="0"
      height="40px"
      position="relative"
      padding="0px 0px 0px 0px"
      placeholder="Placeholder"
      size="default"
      isDisabled={false}
      labelHidden={true}
      variation="default"
    ></SearchField>
    <View
      width="24px"
      height="24px"
      shrink="0"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Icon")}
    >
      <Icon
        width="16px"
        height="19.5px"
        viewBox={{ minX: 0, minY: 0, width: 16, height: 19.5 }}
        paths={[
          {
            d: "M8 19.5C9.1 19.5 10 18.6 10 17.5L6 17.5C6 18.6 6.9 19.5 8 19.5ZM14 13.5L14 8.5C14 5.43 12.37 2.86 9.5 2.18L9.5 1.5C9.5 0.67 8.83 0 8 0C7.17 0 6.5 0.67 6.5 1.5L6.5 2.18C3.64 2.86 2 5.42 2 8.5L2 13.5L0 15.5L0 16.5L16 16.5L16 15.5L14 13.5ZM12 14.5L4 14.5L4 8.5C4 6.02 5.51 4 8 4C10.49 4 12 6.02 12 8.5L12 14.5Z",
            fill: "rgba(51,51,51,1)",
            fillRule: "nonzero",
          },
        ]}
        position="absolute"
        top="10.42%"
        bottom="8.33%"
        left="16.67%"
        right="16.67%"
        onClick={signOut}
      ></Icon>
    </View>
    <Image
      width="45px"
      height="45px"
      shrink="0"
      position="relative"
      borderRadius="160px"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "image")}
    ></Image>
  </Flex>
    
  );
};
