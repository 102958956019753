import './App.css';
import { PrimaryNavBar } from './components/NavBar';
import {
  withAuthenticator,
  ThemeProvider,
  Card
} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import { Outlet } from "react-router-dom";




// TODO: figure out why the header is changing size when I add content to the main view
function App({ signOut }) {
  const theme: Theme = {
    name: 'link-theme',
    tokens: {
      components: {
        link: {
          focus: {
            color: { value: '{colors.blue.40}' },
          },
          hover: {
            color: { value: '{colors.blue.60}' },
          },
          visited: {
            color: { value: '{colors.blue.99}' },
          },
        },
      },
    },
  };
  
  const navigationOptions = [
    ["Horse Feeds", "/"],
    ["Feeds", "/listfeeds"],
    ["Add Feed...", "/addfeed"],
    ["Nutrients", "/listnutrients"],
    ["Add Nutrient...", "/addnutrient"],
    ["Nutrient Measurements", "/listnutrientmeasurements"]
  ];
      // <NavBar width={"100vw"} />

	return (
    <ThemeProvider theme={theme} colorMode="light">
      <PrimaryNavBar menus={navigationOptions} />
      <Card height="100%" variation="elevated">
        <Outlet />
      </Card>
    </ThemeProvider>
	);
}

export default withAuthenticator(App);


